<!--  -->
<template>
  <el-dialog
    title="提示"
    width="900px"
    :show-close="false"
    :visible.sync="detailShow"
    :before-close="beforeClose"
  >
    <template #title>
      <div class="dialog_title">
        <div class="left">{{ nowData.projectName }}</div>
        <i
          class="close el-icon-circle-close"
          @click="$emit('update:detailShow', false)"
        ></i>
      </div>
    </template>
    <div class="dialog_content">
      <div class="box">
        <div class="item">
          <div class="label">项目名称</div>
          <div class="value">
            {{ nowData.projectName }}
          </div>
        </div>
        <div class="item">
          <div class="label">项目所属地</div>
          <div class="value">{{ nowData.region }}</div>
        </div>
        <div class="item">
          <div class="label">所属产业</div>
          <div class="value">{{ nowData.industryName }}</div>
        </div>
        <div class="item">
          <div class="label">项目概述</div>
          <div class="value" v-html="handleDesc"></div>
        </div>
        <div class="item">
          <div class="label">所属园地</div>
          <div class="value">{{ nowData.region }}</div>
        </div>
      </div>
      <div class="box">
        <el-row class="data_list" :gutter="10">
          <el-col :span="4">
            <div class="data">
              <div class="text">投资金额</div>
              <div class="num">{{ nowData.investmentAmount }}<br />万元</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="data">
              <div class="text">项目销售收入</div>
              <div class="num">{{ nowData.saleAmount }}<br />万元</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="data">
              <div class="text">利润</div>
              <div class="num">{{ nowData.profitAmount }}<br />万元</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="data">
              <div class="text">税收</div>
              <div class="num">{{ nowData.taxAmount }}<br />万元</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="data">
              <div class="text">预期投资回收期</div>
              <div class="num">{{ nowData.reportingCycle }}<br />年</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="data">
              <div class="text">就业人数</div>
              <div class="num">{{ nowData.totalEmployed }}<br />人</div>
            </div>
          </el-col>
        </el-row>
        <div class="item">
          <div class="label">项目阶段</div>
          <div class="value">{{ nowData.productStatus }}</div>
        </div>
      </div>
      <div class="box">
        <div class="item">
          <div class="label">责任单位</div>
          <div class="value">{{ nowData.projectunitName }}</div>
        </div>
        <div class="item">
          <div class="label">联系方式</div>
          <div class="value">
            {{ nowData.linkName }}
            <span style="margin-left: 10px">{{ nowData.linkMobile }}</span>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    handleDesc() {
      return (
        this.nowData.projectDesc &&
        this.nowData.projectDesc.replace(/\n/g, "<br />")
      );
    },
  },
  props: {
    detailShow: {
      type: Boolean,
      default: false,
    },
    nowData: {
      type: Object,
      default: () => {},
    },
  },
  created() {},
  mounted() {},
  methods: {
    beforeClose() {
      this.$emit("update:detailShow", false);
    },
  },
};
</script>

<style lang='scss' scoped>
.dialog_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 20px;

  .left {
    padding-left: 15px;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 3px;
      height: 60%;
      border-radius: 5px;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .close {
    cursor: pointer;
    font-size: 25px;
  }
}
.dialog_content {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  color: #666;

  .box {
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
    font-size: 14px;

    .item {
      padding: 13px 0;
      display: flex;
      background-color: #f5f8fd;
      line-height: 24px;

      &:nth-child(2n-1) {
        background-color: #f2f5fc;
      }

      .label {
        width: 110px;
        padding-left: 20px;
        box-sizing: border-box;
        flex-shrink: 0;
      }
      .value {
        color: #333;
        flex-grow: 1;
        padding-left: 10px;
        word-break: break-all;
      }
    }
    .data_list {
      margin-bottom: 10px;
      background-color: #fff;

      .data {
        padding: 15px 0;
        background-color: #f5f8fd;
        text-align: center;

        .num {
          color: $theme;
          margin-top: 10px;
          font-weight: bold;
          line-height: 1.2;
        }
      }
    }
  }
}
</style>
