<!-- 主导产业 -->
<template>
  <div class="move-frame">
    <chance-detail
      :detailShow.sync="detailShow"
      :nowData="detail"
    ></chance-detail>
    <div class="body">
      <top showTitle="走进黑水" :childNavs="navs"></top>
      <div class="content">
        <div class="left">
          <!-- 一级 -->
          <div
            :class="{ type_item: true, active: activeType == item.code }"
            v-for="item in list"
            :key="item.code"
            @click="changeType(1, item)"
          >
            <div class="show">
              <div class="label">{{ item.name }}</div>
              <i class="plus el-icon-plus"></i>
              <i class="minus el-icon-minus"></i>
            </div>
            <!-- 二级 -->
            <div
              class="sub_list"
              v-show="item.industryList && activeType == item.code"
            >
              <div
                :class="{
                  sub_item: true,
                  sub_active: activeSubType == industry.id,
                }"
                v-for="industry in item.industryList"
                :key="industry.id"
                @click="changeType(2, industry)"
              >
                <div class="sub_title">
                  <div class="text">{{ industry.industryName }}</div>
                  <i class="el-icon-caret-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="choose">
            <div class="choice active">产业概述</div>
          </div>
          <div class="desc">
            <div class="summary" v-html="handleDesc"></div>
          </div>
        </div>
      </div>
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import Bottom from "../../components/bottom.vue";
import top from "../../components/top.vue";
import routes from "../../router/moveWater";
import { URL } from "../../util/config";

import ChanceDetail from "../../components/chanceDetail.vue";
export default {
  components: { top, Bottom, ChanceDetail },
  data() {
    return {
      URL,
      navs: routes,
      activeType: "", // 第一级激活类
      activeSubType: "", // 第二级激活类
      activeLastType: "", // 第三级激活类
      list: [],
      detailShow: false, // 投资机会的详情展示
      detail: {}, // 投资机会详情
      nowData: {},
    };
  },
  created() {
    this.getList();
  },
  computed: {
    handleDesc() {
      let handle1 = "";
      let handle2 = "";
      if (this.nowData.summary) {
        handle1 = this.nowData.summary.replace(/ /g, "&nbsp;");
        handle2 = handle1.replace(/\n/g, "<br>");
      }
      return handle2;
    },
  },
  mounted() {},
  methods: {
    async getList() {
      let res = await this.$api.getIndustryDic({
        industryFlag: 1,
        parkFlag: 0,
      });
      this.list = res.data;
      let first = this.list[0];
      this.activeType = this.list[0].code;
      if (first.industryList.length != 0) {
        this.activeSubType = first.industryList[0].id;
        this.nowData = first.industryList[0];
      }
    },
    changeType(level, item) {
      if (item.parkFlag == 1 && level == 1) {
        this.activeSubType = "";
        this.activeLastType = "";
      }
      switch (level) {
        case 1:
          this.activeType = item.code;
          break;
        case 2:
          this.activeSubType = item.id;
          this.nowData = item;
          break;

        default:
          break;
      }
    },
  },
  beforeDestroy() {
    this.map && this.map.destroy();
  },
};
</script>

<style lang='scss' scoped>
.content {
  width: 100%;
  background-color: rgb(250 251 253 / 70%);
  position: relative;
  box-shadow: 0 2px 5px rgba($color: #aaa, $alpha: 0.3);
  padding: 34px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .left {
    width: 300px;
    margin-right: 20px;
    flex-shrink: 0;

    .type_item {
      background-color: #fff;
      padding: 10px;
      margin-bottom: 10px;
      color: #cfcfcf;
      cursor: pointer;
      &:hover {
        color: $theme;
        .show {
          .label {
            color: $theme;
          }
          &::before {
            background-color: $theme;
          }
        }
      }

      .show {
        font-weight: bold;
        padding: 0 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &::before {
          content: "";
          width: 3px;
          height: 60%;
          border-radius: 10px;
          background-color: #cfcfcf;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        .label {
          padding-left: 10px;
          color: #333;
          font-size: 18px;
        }
        .minus {
          display: none;
        }
      }

      .sub_list {
        width: 100%;
        box-sizing: border-box;
        margin-top: 7px;
        background-color: #f0f5fa;
        color: #666;
        max-height: 0;
        transition: maxHeight 0.5s;
        overflow: hidden;
        font-size: 14px;

        .sub_item {
          height: 36px;
          line-height: 36px;
          padding: 0 15px;
          cursor: pointer;

          &:hover {
            color: #fff;
            background-color: $theme;
          }
          &:last-child .sub_title {
            border-bottom: 0;
          }
          .sub_title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #cfcfcf;

            i {
              font-size: 14px;
            }
          }
        }
        .sub_active {
          color: #fff;
          background-color: $theme;
        }
      }
    }

    .active {
      color: $theme;

      .sub_list {
        max-height: 1000px;
      }
      .show {
        &::before {
          background-color: $theme;
        }
        .label {
          color: $theme;
        }
        .plus {
          display: none;
        }
        .minus {
          display: block;
        }
      }
    }
  }
  .center {
    flex-grow: 1;
    position: relative;

    #container {
      width: 100%;
      height: 100%;
    }

    &_title {
      width: 95%;
      height: 40px;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      background-color: rgba(246, 251, 255, 0.7);
      border: 1px solid #fff;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrow {
        color: $theme;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
      .center_label {
        margin-left: 35px;
        font-weight: bold;
        font-size: 18px;
      }
      .center_tags {
        width: fit-content;
        height: 100%;
        line-height: 40px;
        display: flex;
        margin-right: 20px;

        .tag {
          cursor: pointer;
          transition: color 0.5s;
          position: relative;

          &:hover {
            color: $theme;

            &::after {
              width: 100%;
            }
          }
          &::after {
            content: "";
            display: block;
            width: 0;
            height: 2px;
            border-radius: 5px;
            background-color: $theme;
            position: absolute;
            left: 0;
            bottom: -1px;
            transition: width 0.2s;
          }
          &:first-child {
            margin-right: 30px;
          }
        }
        .active {
          color: $theme;
          font-weight: bold;

          &::after {
            width: 100%;
          }
        }
      }
    }

    .bg {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;

      img {
        width: auto;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }
    }
  }
  .right {
    flex-grow: 1;
    background-color: #fff;
    padding: 10px 15px;
    box-sizing: border-box;

    .choose {
      height: 40px;
      border: 1px solid #eee;
      border-radius: 5px;
      display: flex;
      justify-content: space-evenly;
      font-size: 18px;
      line-height: 40px;

      .choice {
        height: 100%;
        cursor: pointer;
        position: relative;

        &:hover {
          color: $theme;

          &::after {
            width: 100%;
          }
        }
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 2px;
          border-radius: 5px;
          background-color: $theme;
          position: absolute;
          left: 0;
          bottom: -1px;
          transition: width 0.2s;
        }
      }
      .active {
        font-weight: bold;
        color: $theme;

        &::after {
          width: 100%;
        }
      }
    }
    .desc {
      font-size: 14px;
      margin-top: 25px;
      padding: 0 5px;
      text-align: justify;
      max-height: calc(100% - 70px);
      overflow: auto;

      .summary {
        line-height: 1.5;
      }
    }
  }
}
@media (max-width: 1700px) {
  .content .right {
    width: 400px;

    .choose {
      font-size: 15px;
    }
    .desc {
      font-size: 13px;
    }
  }
}
@media (max-width: 1500px) {
  .content {
    .center_title .center_label {
      font-size: 16px;
    }
    .left {
      width: 200px;

      .type_item {
        font-size: 14px;

        .show .label {
          font-size: 16px;
        }
      }
    }
    .right {
      width: 300px;

      .desc .invests {
        font-size: 14px;
      }
    }
  }
}
</style>
